<template>
    <div>
        <div class="toast">{{config.title}}</div>
    </div>
</template>
<script>
    export default{
        props:{
            config:{
                type:Object,
            }
        },
        data(){
            return{
                value: '',
                  
            }
        },
        methods:{

        }
    }
</script>
<style>
    .toast{
        width:200px;text-align:center;
        padding: 20px;
        font-size: 28px;
        background:rgba(0,0,0,.5) ;
        border-radius: 20px;
        color: #fff;
        position: fixed;
        z-index: 999;
        top: 45%;
        left: 40%
    }
</style>
